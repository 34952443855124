/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
  useLayoutEffect,
  MutableRefObject,
} from 'react'
import { on, off } from '@arsenal/arsenal/modules/event'
import Router, { SingletonRouter } from 'next/router'
import fetch from 'isomorphic-unfetch'

import getUrl from 'lib/url'
import Loading from 'Components/loading'
import { GlobalStoreContext } from 'Components/store'

// function useIntersection(options) {
//   const [observerEntry, setEntry] = useState({})
//   const elRef = useRef()

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       entries => setEntry(entries[0]),
//       options
//     )
//     observer.observe(elRef.current)
//     return () => observer.disconnect()
//   }, [elRef])
//   return { observerEntry, elRef }
// }

export function usePrefetch(urls: string[]) {
  useEffect(() => {
    urls.forEach(url => {
      Router.router && Router.router.prefetch(url)
    })
  }, [])
}
interface IUseIntervalAniamtion {
  /**
   * 起始序号
   */
  start: number
  /**
   * 最大序号
   */
  max: number
  /**
   * 当鼠标在ref范围之内时，停止计时动画
   */
  ref: React.RefObject<HTMLElement>
  /**
   * 计时到达之后的回调
   * @param curr 要激活的index
   */
  callback(curr: number): void
  /**
   * 时间间隔
   */
  interval?: number
}
export function useIntervalAnimation({
  start = 0,
  max,
  ref,
  callback,
  interval = 5000,
}: IUseIntervalAniamtion) {
  const timerRef = useRef<NodeJS.Timeout>()
  const mouseRef = useRef<boolean>(false)
  function clear() {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }
  function invoke() {
    // 鼠标在范围之内时，不执行动画
    if (mouseRef.current) {
      return
    }
    clear()
    timerRef.current = setTimeout(() => {
      const next = start + 1 > max ? 0 : start + 1
      callback(next)
    }, interval)
  }
  function mouseEnter() {
    mouseRef.current = true
    clear()
  }
  function mouseLeave() {
    mouseRef.current = false
    invoke()
  }
  useEffect(() => {
    invoke()
  }, [start])
  useEffect(() => {
    // 绑定mouse in与mouse out事件
    if (!ref || !ref.current) {
      return
    }
    on(ref.current, 'mouseenter', mouseEnter)
    on(ref.current, 'mouseleave', mouseLeave)
    return () => {
      clear()
      off(ref.current, 'mouseenter', mouseEnter)
      off(ref.current, 'mouseleave', mouseLeave)
    }
  }, [])
}

/**
 * 获取城市名，初始状态为undefined，如果获取不到为''，在使用时，尽量如下使用
```
  const cityName = useCityName()
  useEffect(() => {
    if (typeof cityName === 'undefined') {
      return
    }
    fetch(`/api/?city_name=${encodeURIComponent(cityName.replace('未知', ''))}`)
  }, [cityName])

```
 */
export function useCityName() {
  const { state } = useContext(GlobalStoreContext)
  return state.cityName
}

export function useBrandList() {
  const { state, dispatch } = useContext(GlobalStoreContext)
  if (state.brands) {
    return state.brands
  }
  fetch(getUrl('/motor/car_page/v1/all_brand/'))
    .then(res => res.json())
    .then(json => {
      if (!json.data || json.message !== 'success') {
        throw new Error('Data error in fetching brands')
      }
      const letters = json.data.map((item: any) => item.pinyin) as string[]
      const brands = json.data.map((item: any) => {
        const obj: any = {}
        obj.letter = item.pinyin
        obj.data = item.data.map((inner: any) => ({
          id: inner.id,
          name: inner.brand,
          avatar: inner.toutiaourl,
        }))
        return obj
      })
      dispatch({
        type: 'setBrands',
        payload: {
          letters,
          list: brands,
        },
      })
    })
    .catch(err => {
      dispatch({
        type: 'setBrands',
        payload: undefined,
      })
      throw new Error(err)
    })
  return
}

/**
 *  监听滚动联动
 * @param getElements 获取需要监听的元素
 * @returns [current, setCurrent, ref]
```
const [current, setCurrent, ref] = useScrollSpy2<HTMLDivElement>(node => {
  return node.children
})
...
<div ref={ref}>
  <section></section>
  <section></section>
  <section></section>
</div>
<div>
  <div onClick={() => {setCurrent(2)}}>A</div>
</div>
```
 */
export function useScrollSpy<T extends HTMLElement, U extends HTMLElement>(
  getElements: (node: T) => NodeListOf<U>,
  globalScroll = true
): [
  number,
  (index: number, parent?: any, offset?: number) => void,
  (node: T) => void
] {
  // 当前滚动到第几个元素
  const [current, setCurr] = useState<number>(0)
  const [elements, setElements] = useState<HTMLElement[]>([])
  const [rootElement, setRootElement] = useState<T | null>(null)
  const clickRef = useRef(false)
  // 初始化是忽略
  const initRef = useRef(true)
  useEffect(() => {
    if (elements.length === 0) {
      return
    }
    const observer: IntersectionObserver = new IntersectionObserver(
      changes => {
        // 初始化时忽略
        if (initRef.current) {
          initRef.current = false
          return
        }
        // 如果是通过ScrollInToView产生的滚动则不执行Observer， 避免冲突
        if (clickRef.current) {
          clickRef.current = false
          return
        }
        changes.forEach(change => {
          const ele = change.target as HTMLElement
          const { bottom } = change.boundingClientRect
          const { height } = change.rootBounds || {
            height: document.documentElement.clientHeight,
          }
          // console.log(change, ele, bottom, height)
          // 只关注顶部变动
          if (bottom <= height) {
            // 离开可视区域，获取最后一个，默认顺序
            // chrome55缺少isIntersecting字段
            const index = elements.findIndex(element => element === ele)
            setCurr(change.isIntersecting === false ? index + 1 : index)
          }
        })
      },
      {
        root: globalScroll ? null : rootElement,
        // root: rootElement.current,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.0000001],
      }
    )
    // 每个需要监听的区域块
    elements.forEach(element => {
      observer.observe(element)
    })
    return () => {
      observer.disconnect()
    }
  }, [elements.length])

  const ref = useCallback((node: T) => {
    if (node !== null) {
      setRootElement(node)
      setElements(Array.from(getElements(node)) as HTMLElement[])
    }
  }, [])
  function setCurrent(
    index: number,
    scrollContainer?: HTMLElement,
    offset?: number
  ): void {
    const offsetValue = offset || 0
    if (elements[index]) {
      clickRef.current = true
      const parent = elements[index].parentElement
      if (scrollContainer) {
        scrollContainer.scrollTop = elements[index].offsetTop - offsetValue
      } else if (parent) {
        parent.scrollTop = elements[index].offsetTop
      }

      setCurr(index)
    }
  }
  return [current, setCurrent, ref]
}

// 与Spy1实现相同的功能 但在实现上做了修改。
// 主要区别是➡由依赖ref callback 改为依赖dependency （利用useEffect一定是在渲染完成后执行的性质）
// 并在getELements外加setTimeout延时。
//（useEffect后，依然有不确定原因的DOM变化，setTimeout是个暂时的解决方案，后续还需探究dom变化原因。）
export function useScrollSpy_v2<T extends HTMLElement, U extends HTMLElement>(
  getElements: (node: T) => NodeListOf<U>,
  globalScroll = true,
  dependencies: any[]
): [
  number,
  (index: number, parent?: any, offset?: number) => void,
  (node: T | null) => void
] {
  // 当前滚动到第几个元素
  const [current, setCurr] = useState<number>(0)
  const [rootElement, setRootElement] = useState<T | null>(null)
  const elementsRef = useRef<HTMLElement[] | null>(null)
  const clickRef = useRef(false)
  // 初始化时忽略
  const initRef = useRef<boolean | null>(true)

  const observerRef = useRef<IntersectionObserver | null>(null)
  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      changes => {
        const elements = elementsRef.current
        if (!elements) {
          return
        }

        // 初始化时忽略
        if (initRef.current) {
          initRef.current = false
          return
        }
        // 如果是通过ScrollInToView产生的滚动则不执行Observer， 避免冲突
        if (clickRef.current) {
          clickRef.current = false
          return
        }
        changes.forEach(change => {
          const ele = change.target as HTMLElement
          const { bottom } = change.boundingClientRect
          const { height } = change.rootBounds || {
            height: document.documentElement.clientHeight,
          }
          // console.log(change, ele, bottom, height)
          // 只关注顶部变动
          if (bottom <= height) {
            // 离开可视区域，获取最后一个，默认顺序
            // chrome55缺少isIntersecting字段
            const index = elements.findIndex(element => element === ele)
            setCurr(change.isIntersecting === false ? index + 1 : index)
          }
        })
      },
      {
        root: globalScroll ? null : rootElement,
        // root: rootElement.current,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.0],
      }
    )
  }, [])

  useEffect(() => {
    const observer = observerRef.current
    if (!observer) {
      return
    }
    if (!rootElement) {
      return
    }
    // setTimeout(()=>{

    // },0)
    let elements

    // 使用setTimeout来确保getElements拿到最新的dom
    setTimeout(() => {
      elements = Array.from(getElements(rootElement))

      elementsRef.current = elements
      if (elements.length === 0) {
        return
      }
      // 每个需要监听的区域块
      elements.forEach(element => {
        observer.observe(element)
      })
    }, 300)
    return () => {
      observer.disconnect()
    }
  }, [rootElement, ...dependencies])

  const ref = useCallback((node: T | null) => {
    if (node !== null) {
      setRootElement(node)
    }
  }, [])

  function setCurrent(
    index: number,
    scrollContainer?: HTMLElement,
    offset?: number
  ): void {
    const offsetValue = offset || 0
    const elements = elementsRef.current
    if (!elements) {
      return
    }
    if (elements[index]) {
      clickRef.current = true
      const parent = elements[index].parentElement
      if (scrollContainer) {
        scrollContainer.scrollTop =
          elements[index].getBoundingClientRect().top -
          offsetValue +
          window.pageYOffset
      } else if (parent) {
        parent.scrollTop =
          elements[index].getBoundingClientRect().top + window.pageYOffset
      }

      setCurr(index)
    }
  }
  return [current, setCurrent, ref]
}

export interface IFeedAdData {
  type: 2004 | 2005 | 2006
  fix_position: number
  id: number
  id_str: string
  image_list: [
    {
      url: string
      width: number
      height: number
    }
  ]
  image_mode: number
  label: string
  log_extra: string
  ad_log_id: string
  materiel_id: string
  materiel_str: string
  rit: number
  rit_str: string
  source: string
  time: number
  title: string
  web_title: string
  web_url: string
  unique_id_str: string
  inserted?: boolean | undefined
  track_url_list?: string[]
  click_track_url_list?: string[]
}

interface IFeedAdResult {
  status: number
  prompts?: string
  data: IFeedAdData[]
}
/**
 * 获取feed流广告位
 * @param category 频道名
 * @param page 页面名
 */
export function useFeedAd(category: string, page = 'home') {
  const [feedAd, setFeedAd] = useState<IFeedAdData[]>([])
  const cityName = useCityName()

  // 广告接口，资讯页page为information，只针对广告接口对page做修改
  const curPage = page === 'feed' ? 'information' : page
  useEffect(() => {
    if (cityName === undefined) {
      return
    }
    const url = `/motor/ad/m/pc/channel_fixed_position?page=${curPage}&category=${category}&city_name=${encodeURIComponent(
      cityName || ''
    )}`
    fetch(url, {
      credentials: 'include',
    })
      .then(res => res.json())
      .then((result: Partial<IFeedAdResult>) => {
        if (!result || !result.data || result.status !== 0) {
          result && result.prompts && alert(result.prompts)
          throw new Error(`Get Feed Error ${JSON.stringify(result)}`)
        }
        setFeedAd(result.data)
      })
      .catch(err => {
        throw new Error(err)
      })
  }, [cityName, page, category])
  return feedAd
}
