import useVisible from 'Components/hooks/use-visible'
import { useInView } from 'react-intersection-observer'
import { useEffect, useMemo, useCallback, useRef } from 'react'
import { sendTeaAdEvent, sendAdMonitor } from 'lib/tea-analyze'
import { IDefaultAd } from './interface'
// 根据广告数据返回对应的埋点数据
export function getAdStatData(info: IDefaultAd) {
  return {
    ad_material_id: info.materiel_str,
    ad_target_url: info.web_url,
    log_extra: info.log_extra,
    ad_id: info.id_str,
    ad_req_id: JSON.parse(info.log_extra).req_id,
  }
}

type TUseAdStat = (
  eventName: {
    /**
     * 下发事件名
     */
    send: string
    /**
     * 展示事件名
     */
    show: string
    /**
     * 点击事件名
     */
    click: string
  },
  info: IDefaultAd,
  customStat?: {
    [key: string]: string | number
  },
  throttleTime?: number
) => {
  ref(node?: Element | null | undefined): void
  handleClick(custom?: { [key: string]: string | number }): void
}

// 发送下发和展现事件
export const useAdStat: TUseAdStat = (
  eventName,
  info,
  customStat,
  throttleTime = 1000
) => {
  const [ref, inView] = useInView({
    threshold: 0,
  })
  const isVisible = useVisible()
  const adData = useMemo(() => {
    return {
      ad_material_id: info.materiel_str,
      ad_target_url: info.web_url,
      log_extra: info.log_extra,
      ad_id: info.id_str,
      ad_req_id: JSON.parse(info.log_extra).req_id,
      ...customStat,
    }
  }, [customStat, info.id_str, info.log_extra, info.materiel_str, info.web_url])
  const sendRef = useRef(false)
  const showTimer = useRef<number>()
  useEffect(() => {
    if (adData && !sendRef.current && eventName.send) {
      // 广告下发事件
      sendTeaAdEvent(eventName.send, adData)
      sendRef.current = true
    }
  }, [adData, eventName.send])
  useEffect(() => {
    // 广告展现事件
    // console.log(info.track_url_list, inView, isVisible, adData, eventName.show)
    if (inView && isVisible && eventName.show) {
      if (!showTimer.current) {
        sendTeaAdEvent(eventName.show, adData)
        if (info.track_url_list) {
          info.track_url_list.forEach(url => {
            sendAdMonitor(url, info.ad_log_id)
          })
        }
        showTimer.current = window.setTimeout(() => {
          showTimer.current = 0
        }, throttleTime)
      }
    }
  }, [
    inView,
    isVisible,
    adData,
    eventName.show,
    info.track_url_list,
    info.ad_log_id,
  ])
  // 广告点击事件
  const handleClick = useCallback(
    (custom = {}) => {
      sendTeaAdEvent(eventName.click, {
        ...adData,
        ...custom,
      })
      if (info.click_track_url_list) {
        info.click_track_url_list.forEach(url => {
          sendAdMonitor(url, info.ad_log_id)
        })
      }
    },
    [adData, eventName.click, info.ad_log_id, info.click_track_url_list]
  )

  return {
    ref,
    handleClick,
  }
}
