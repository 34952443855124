import * as React from 'react'
export interface ScrollLoaderProps {
  // 判断是否正在加载中
  loading?: boolean
  /** 是否加载完毕 */
  end: boolean
  /** 垂直方向滚动的边界 */
  verticalMargin?: number
  /**
   * 加载执行的回调
   */
  loadMoreHandler(): void
}

export default class ScrollLoader extends React.PureComponent<
  ScrollLoaderProps,
  {},
  any
> {
  private observer: null | any = null

  private HEIGHT = 0

  private dom = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.HEIGHT = document.documentElement.clientHeight
    this.addObserver()
  }

  componentDidUpdate(prevProps: ScrollLoaderProps) {
    const { end } = this.props
    // 加载结束，卸载observer
    if (!prevProps.end && end) {
      this.removeObserver()
    } else if (prevProps.end && !end && !this.observer) {
      this.addObserver()
    }
  }

  componentWillUnmount() {
    this.removeObserver()
  }

  private addObserver = () => {
    const { verticalMargin = 10 } = this.props
    this.observer = new IntersectionObserver(
      changes => {
        changes.forEach(change => {
          // 某些浏览器intersectionRatio有问题，需要再判断位置
          if (
            change.intersectionRatio <= 0 &&
            change.boundingClientRect.bottom - verticalMargin - 30 > this.HEIGHT
          ) {
            return
          }
          const { end, loading, loadMoreHandler } = this.props
          if (!end && !loading) {
            // 这里的loadMoreHandler一定要这样取，
            // 否则会导致跟hooks共用时的问题
            loadMoreHandler()
          }
        })
      },
      {
        threshold: [0.00001],
        rootMargin: `${verticalMargin}px 10px`,
      }
    )
    this.observer.observe(this.dom.current)
  }

  private removeObserver() {
    if (this.observer) {
      this.observer.unobserve(this.dom.current)
      this.observer = null
    }
  }

  render() {
    const { children } = this.props
    return (
      <React.Fragment>
        {children}
        <div ref={this.dom} style={{ height: '1px' }} />
      </React.Fragment>
    )
  }
}
