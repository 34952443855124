import * as React from 'react'

const Loading = () => (
  <div>
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
      <span>加载中…</span>
    </div>
    <style jsx>
      {`
        .spinner {
          padding: 20px 0;
          text-align: center;
          font-size: 0;
        }

        .spinner > div {
          display: inline-block;
          vertical-align: middle;
          width: 4px;
          height: 4px;
          margin-right: 6px;
          background-color: #c1c1c1;
          border-radius: 100%;
          animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        }

        .spinner .bounce1 {
          animation-delay: -0.32s;
        }

        .spinner .bounce2 {
          animation-delay: -0.16s;
        }
        span {
          font-size: 12px;
          color: #666;
          margin-left: 6px;
          vertical-align: middle;
        }
        @keyframes sk-bouncedelay {
          0%,
          80%,
          100% {
            transform: scale(0);
          }
          40% {
            transform: scale(1.5);
          }
        }
      `}
    </style>
  </div>
)

export default Loading
